import React from 'react'
import Child from '../Child/Child'

function Couple() {
  return (
    <>
  


<Child/>


  
    
    
    
    </>
    
    
    )
}

export default Couple