import './App.css';
import React,{useState, useEffect} from 'react';
import { Route, Routes } from "react-router-dom";
import Home from "../src/Screens/Home/Home"
import About from './Screens/About/About';
import Service from './Screens/Service/Service';
import Contact from './Screens/Contact/Contact';
import BookNow from './Components/BookNow/BookNow';
import Family from './Components/DropDown/Family/Family';
import Child from './Components/DropDown/Child/Child';
import Couple from './Components/DropDown/Couples/Couple';
import HashLoader from "react-spinners/HashLoader";
function App() {
  const  [loading ,setLoading] = useState(false);

    useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  
  }, [])
  return (
    <>
{
   loading ?
       <HashLoader

        color={'#01323B'}
        loading={loading}
        id='loader'
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      :
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/booknow' element={<BookNow />} />
        {/* DropDown*/}
        <Route path='/family-psychotheraphy' element={<Family />} />
        <Route path='/child-psychotheraphy' element={<Child />} />
        <Route path='/couple-psychotheraphy' element={<Couple />} />
      </Routes>

}
    
    </>
  );
}

export default App;
