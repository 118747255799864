import React from 'react'
import "../Footer/FooterStyle.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import logo from "../../assets/Images/logo.png";
import { Link } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter,AiOutlineInstagram,AiFillYoutube } from 'react-icons/ai';
import {IoIosArrowForward} from "react-icons/io"
function Footer() {
    return (
        <>
            <Container fluid className='bg'>



                <Container fluid="sm" style={{ width: '80%', marginBottom: "10px" }} data-aos="fade-right" data-aos-duration="1000">
                    <Row >
                        <Col lg={5}>
                            <div className="rightSideSec">
                                <h2 className='footerHeading'>Subscribe To Our Newsletter</h2>
                                <p className='footerText'>Receive news and updates by subscribing to our newsletter today.</p>

                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Enter your email address"
                                        aria-label="Enter your email address"
                                        aria-describedby="basic-addon1"
                                        id='footerInput'
                                    />
                                </InputGroup>
                                <div className="">
                                    <button className='footerCustomBtn'>Subscribe Now <IoIosArrowForward/></button>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4}>
                            <div className="leftSideSec">
                                <h2 className='footerContactUs'>Contact Us</h2>
                                <p className='footerPhone'>Phone: 07900331552</p>
                                <p className='footerEmail'>Email: info@lgtherapy.co.uk</p>
                            </div>
                        </Col>
                    </Row>

                    <hr style={{ color: "#c9c9c9" }} />
                </Container>

                {/* Secound part of Footer */}
                <Container style={{ width: '80%', marginTop: "10px" }} data-aos="fade-left" data-aos-duration="1000">
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div className="">
                                <div className="footerLogoContainer">
                                    <img src={logo} alt=""  className='img-fluid' id="footerLogo"/>
                                </div>
                             <h2 className='f-heading'>Louise Glover Psychotherapy And Counselling</h2>
                              
                            <p className='f-text'>LG Psychotherapy and Counselling is both physical and 
                            <span className='color-gold'> online therapy counselling </span> services devoted to helping people who are struggling with mental health issues so they can live happier and more fulfilling life.</p>
                            </div>

                        </Col>


                        <Col sm={12} md={6} lg={2}> 
                        
                        <div className="footerLinks">
                            
                        <h3 className='qMenu'>Quick Menu</h3>
                                   <div className="">
                                    <ul className='p-0'> 
                                        <li><Link to="/" id='link'>Home</Link></li>
                                        <li><Link  id='link'>About Us</Link></li>
                                        <li><Link id='link'>Service</Link></li>
                                        <li><Link id='link'>Contact Us</Link></li>
                                   
                                    </ul>
                                   </div>
                            </div></Col>


                        <Col sm={12} md={6} lg={3}>

                            <div className="footerLinks">

                                <h3 className='serviceHeading'>Services</h3>

                                  
                                  <ul className='p-0'>
                                    <li  id='linkText'>Family Psychotheraphy</li>
                                    <li id='linkText'>Couples Psychotheraphy</li>
                                   

                                    <li id='linkText'>Child Psychotheraphy</li>
                                  </ul>
                              
                            </div>
                        </Col>

                        <Col sm={12} md={6} lg={2}> 
                        
                        
                            <div className="footerLinks">

                                <h3 className='followHeading'>Follow Us</h3>

                              <ul className='iconContainer'> 
                                <li>  <FaFacebookF id='socialIcon'/></li>
                                <li> <AiOutlineTwitter id='socialIcon'/></li>
                                <li> <AiOutlineInstagram id='socialIcon'/></li>
                                <li> <AiFillYoutube id='socialIcon'/></li>
                             
                              </ul>
                            </div>
                        </Col>
                    </Row>


                    <hr style={{ color: "#c9c9c9" }} />

                    <div className="text-center" >
                        <p className='copyRightText'>Copyright© 2022 - All Rights Reserved.</p>
                    </div>
                </Container>

            </Container>

        </>


    )
}

export default Footer