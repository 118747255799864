import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../assets/Images/logo.png";
import "../Nav/NavStyle.css"
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
function NavTopBar() {


  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }

  return (
    <>
      <Navbar bg="transparent" expand="lg" id='navbar' style={{background:"black"}}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} className="navLogo" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" >
            <Nav className="justify-content-center">
              <Nav.Link className='homeNav' ><Link className='homeLink' to="/">Home</Link>  </Nav.Link>
              <Nav.Link className='aboutNav'>  <Link className='aboutLink' to="/about"> About Us  </Link> </Nav.Link>       

              <Dropdown   show={show} id="basic-nav-dropdown"
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}>
        <Dropdown.Toggle className="dropdown">
        <Link to="/service" id="dropLink" >Service</Link>
        </Dropdown.Toggle>
        
        <Dropdown.Menu>
          <Dropdown.Item ><Link to="/family-psychotheraphy" id="Link"> Family Psychotheraphy</Link></Dropdown.Item>
          <Dropdown.Item><Link to="/child-psychotheraphy" id="Link"> Child Psychotheraphy</Link></Dropdown.Item>
          <Dropdown.Item ><Link to="/couple-psychotheraphy" id="Link"> Couple  Psychotheraphy</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

              <Nav.Link className="contactNav"> <Link className="contactLink" to="/contact">Contact Us </Link> </Nav.Link>
            </Nav>

            <hr className="vertical" />




            <Navbar.Collapse className="justify-content-end">

              <div className="text-center">
              <button className='customBtn'> <Link to="/booknow" id="customBtnLink">Book Now <IoIosArrowForward/> </Link>  </button>
              </div>
               
    
            </Navbar.Collapse>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      {/* <Cards/> */}
    </>

  );
}

export default NavTopBar;