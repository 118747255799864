import React from "react";
import NavTopBar from "../../Components/Nav/NavTopBar";
import AboutSec from "../../Components/AboutSec/AboutSec";
import OurServiceSec from "../../Components/OurServiceSec/OurServiceSec";
import "../../Components/Slider/SliderStyle.css";
import Donation from "../../Components/Donation/Donation";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Footer from "../../Components/Footer/Footer";
import Slider from "../../Components/Slider/Slider";
import Cards from "../../Components/Cards/Cards";
const Home = () => {

    return (
        <>
            <NavTopBar />
            <Slider/>
            <Cards />
            <AboutSec />
            <OurServiceSec />
            <Donation />
            <Testimonials />
            <Footer />

        </>
    )

}
export default Home