import React from 'react'
import "../Service/ServiceStyle.css"
import NavTopBar from '../../Components/Nav/NavTopBar'
import Footer from "../../Components/Footer/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import services1 from "../../assets/Images/Cards/services1.jpg";
import services2 from "../../assets/Images/Cards/services2.jpg"
import services3 from "../../assets/Images/Cards/services3.jpg";
import services4 from "../../assets/Images/Cards/services4.jpg";
import services5 from "../../assets/Images/Cards/services5.jpg";
import services6 from "../../assets/Images/Cards/services6.jpg";
import { IoIosArrowForward } from "react-icons/io";
function Service() {
  return (
   <>
   <NavTopBar/>

   <div className='serviceContainer'></div>



   <div className="bg-light py-5">
    <Container  style={{ width: '80%', marginBottom: "10px" }}>
      <Row >
        <Col sx={12} md={6} lg={10}>
            <div className="">
            <h5 className='seviceHeading' data-aos="fade-right"  data-aos-duration="1000"> OUR SERVICES</h5>
             
           
              
            </div>
            <h1 className='serviceSubHaeding' data-aos="fade-up"  data-aos-duration="1000">Counseling Services</h1>
        </Col>
 
        <Col sx={12} md={6} lg={2}>

            <div className="">
                <button className='serviceCustomBtn' data-aos="fade-left"  data-aos-duration="1000">More Service <IoIosArrowForward/> </button>
            </div>
        </Col>
      </Row>


    </Container>

    <Container className='bg-light' style={{ width: '80%', marginBottom: "10px" }}> 
      <Row >
        <Col sm={12} md={12} lg={4} >
            <div className="mx-full">
            <div className="customCard" data-aos="fade-up"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services1} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Child Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
 
        <Col sm={12} md={12} lg={4}>
            <div className="mx-full">
            <div className="customCard" data-aos="zoom-in"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services2} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Couples Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
        <Col sm={12} md={12} lg={4}>
            <div className="mx-full">
            <div className="customCard" data-aos="fade-up"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services3} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Family Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
      </Row>


      <Row className='my-5'>
        <Col sm={12} md={12} lg={4} >
            <div className="mx-full">
            <div className="customCard" data-aos="fade-up"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services4} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Child Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
 
        <Col sm={12} md={12} lg={4}>
            <div className="mx-full">
            <div className="customCard" data-aos="zoom-in"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services5} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Couples Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
        <Col sm={12} md={12} lg={4}>
            <div className="mx-full">
            <div className="customCard" data-aos="fade-up"  data-aos-duration="1000">
      <Card.Img variant="top" className='rounded-3' src={services6} />
      <Card.Body id="cardBody">
        <Card.Title id="cardTitle">Family Psychotheraphy</Card.Title>
        <Card.Text id="cardText">
        We work with children and young people to assess and treat the psychological difficulties they are going through. If you have any trouble traveling with your child, we also offer online therapy counselling services.
        </Card.Text>
        <div className="customBtnCardContainer">
        <button className='customSmBtn'>Learn More <IoIosArrowForward/> </button>
        </div>
      
      </Card.Body>
    </div>
            </div>
        </Col>
      </Row>
      
    </Container>


    </div>



   <Footer/>
   
   
   
   
   
   
   
   </>
  )
}

export default Service