import React, { useState } from 'react';
import NavTopBar from '../Nav/NavTopBar';
import Footer from '../Footer/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "../BookNow/BookNowStyle.css";
import booksec from "../../assets/Images/book-now/booksec.jpg";
import { IoIosArrowForward } from "react-icons/io";
import Alert from 'react-bootstrap/Alert';

function BookNow() {
  const [error, setError] = useState(false);
const [name , setName] = useState('');
const [email , setEmail] = useState('');
const [phone , setPhone] = useState('');
const [select , setSelect] = useState('');


  const HandleSubmit = (e) =>{
  e.preventDefault();
  if (name.length === 0 || email.length === 0 || phone.length === 0) {
    setError(true);
    return;
  }
 alert(name,email,phone,select);


  }
  return (
    <>
      <NavTopBar />

<div className="bookNowContainer"></div>
      <Container>

        <Row>

          <Col lg="6" sm="12" md="12" className='my-auto'>
            <div className='py-5' data-aos="fade-right" data-duration-aos="1000">

              <h4 className='bookTitle'>APPOINTMENT</h4>
              <h1 className='bookHeading'>Book An Appointment</h1>

              <p className='bookText'>Erat magna eu fringilla dolor, tincidunt dictum ultrices varius mi scelerisque consectetur. Elit proin dui adipiscing dignissim sagittis, ultrices eu. Adipiscing ante consectetur</p>
            </div>
            <div className="img" data-aos="fade-up" data-duration-aos="1000">
              <img src={booksec} className='img-fluid rounded-3' alt="" />
            </div>
          </Col>

          <Col lg="6" sm="12" md="12" className='py-5'>

            <div className="FormContainer" data-aos="fade-left" data-duration-aos="1000">
              <Form>

                <div className="fomrTopHeading">
                  <h2 className='bookHeading'>Booking A Mental Health Appointment</h2>
                  <p className='bookText'>Donec adipiscing dignissim proin Sed libero amet</p>
                </div>
                <Form.Group className="mb-3 mx-5" >

                  <Form.Control
                   type="text"
                  id='nameInput'
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }} />
                </Form.Group>

                {error && name.length <= 0 ? (
                <Alert  variant="danger" className='mb-3 mx-5'>
                  Name can't be Empty!
                </Alert>
              ) : (
                ""
              )}

                <Form.Group className="mb-3 mx-5" >

                  <Form.Control 
                  type="email"
                   id="emailInput"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }} />

                </Form.Group>
                {error && email.length <= 0 ? (
                <Alert  variant="danger" className='mb-3 mx-5'>
                  Email can't be Empty!
                </Alert>
              ) : (
                ""
              )}

                <Form.Group className="mb-3 mx-5" >

                  <Form.Control 
                  type="number" 
                  id="phoneInput" 
                  placeholder="Phone" 
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  />
                </Form.Group>

                {error && phone.length <= 0 ? (
                <Alert  variant="danger" className='mb-3 mx-5'>
                  Phone Number can't be Empty!
                </Alert>
              ) : (
                ""
              )}

                <Form.Select className="mb-3 option" aria-label="Default select example" id='selectField'
                  value={select}
                  onChange={(e) => {
                    setSelect(e.target.value);
                  }}>
   
                  <option value={select} className='option'>Family Psychotheraphy</option>
                  <option value={select} className='option'>Child Psychotheraphy</option>
                  <option value={select} className='option'>Chouples Psychotheraphy</option>
                </Form.Select>

                <div className="mx-5">
                  <button className='custombtn' onClick={HandleSubmit} type="submit">
                    Submit < IoIosArrowForward />
                  </button>

                </div>

              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default BookNow