import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Donation/DonationStyle.css";
import donate from "../../assets/Images/donate.jpg"
import Card from 'react-bootstrap/Card';
import { IoIosArrowForward } from "react-icons/io"
import Counter from '../Counter/Counter';
function Donation() {
    return (
        <>
       
                <Container style={{ width: '80%', marginBottom: "30px" ,marginTop:'30px'}}>
                    <Row>
                        <Col xs={12} md={6} lg={6} className='my-auto'>
                            <div className="left">
                                <h3 className='dHeading' data-aos="fade-right" data-aos-duration="1000">DONATIONS</h3>
                                <h1 className='dSubHeading' data-aos="fade-up" data-aos-duration="1000">
                                    Your Donation Can Help Change Someone’s Life
                                </h1>
                                <div className="dText" data-aos="fade-down" data-aos-duration="1000">
                                    <p>Make a donation today to lend a helping hand to those who need it the most. Every effort, no matter how small, counts. You can help us make mental health accessible to all by supporting our therapy online UK services. </p>

                                </div>
                                <div className="mb-sm-5" data-aos="fade-right" data-aos-duration="1000">
                                    <button className='customBtnDonation'>Make A Donation <IoIosArrowForward/></button>
                                </div>
                            </div>

                        </Col>
                        <Col xs={12} md={6} lg={4} className="offset-lg-2">
                            <div data-aos="fade-left" data-aos-duration="1000">
                                <img src={donate} className='img-fluid' alt="" />
                            </div>
                        </Col>
                    </Row>


                </Container>
          
            {/* Video Section */}
            <Container style={{ width: '80%', marginBottom: "10px" }}>


                <div className="p-0">
                    <div className="row g-0">
                        <div className="col-lg-9 col-sm-12">
                            <div className="p-0" data-aos="fade-up" data-aos-duration="1000">
                                <iframe width="100%" height="500" src="https://www.youtube.com/embed/XHOmBV4js_E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">

                            <div className="cardsCollection">
 
                                <Card id='smallCard' data-aos="fade-left" data-aos-duration="500">
                                    <Card.Body>
                                        <Card.Title ><Counter id='cardTitle'  className="number"  number={1000} title="+"  />
                                        </Card.Title>
                                        <Card.Text id='cardText'>
                                            Successfull Therapy
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <Card id='smallCard' data-aos="fade-left" data-aos-duration="800">
                                    <Card.Body>
                                        <Card.Title id='cardTitle'> <Counter  className="number" number={105} title="+" /> </Card.Title>
                                        <Card.Text id='cardText'>
                                            Medical Branch
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                <Card id='smallCard' data-aos="fade-left" data-aos-duration="1000">
                                    <Card.Body>
                                        <Card.Title id='cardTitle'> <Counter  className="number" number={100} title="K"/></Card.Title>
                                        <Card.Text id='cardText'>
                                            Happy Children
                                        </Card.Text>
                                    </Card.Body>
                                </Card>


                            </div>

                        </div>

                    </div>
                </div>
            </Container>

        </>)
}



export default Donation
