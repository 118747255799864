import React from 'react';
import "../About/AboutStyle.css";
import NavTopBar from '../../Components/Nav/NavTopBar';
import Footer from '../../Components/Footer/Footer';
import Testimonials from '../../Components/Testimonials/Testimonials';
import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowForward } from "react-icons/io";
import about from "../../assets/Images/about.jpg";
import "../../Components/Testimonials/TestimonialStyle.css"

function About() {
  return (
    <>
      <NavTopBar />
      <div className='aboutSection'>


      </div>


      <Container className='AboutInnerSec' style={{ width: "80%" }}>
        <Row>
          <Col xs={12} md={6} lg={7}>
            <div className="leftSide">
              <h3 className='aboutHeading' data-aos="fade-right" data-aos-duration="1000">LOUISE GLOVER PSYCHOTHERAPY AND COUNSELLING</h3>
              <h1 className='SubHeading' data-aos="fade-up" data-aos-duration="1000">
                Helping People Step Towards Wellness
              </h1>
              <div>
                <p className='about-Text' data-aos="fade-down" data-aos-duration="1000">
                  At Louise Glover Psychotherapy and Counselling, Louise Glover is committed to providing clients with specialized, evidence-based treatment to help them overcome their mental health challenges and reach their full potential. She is a highly trained and experienced professional who offers a range of therapeutic interventions, including counselling, psychological therapy, mental health talking therapy, cognitive-behavioral therapy, acceptance and commitment therapy, to name a few. Dedicated to empowering her clients, Glover takes an active role in their own healing process. As a professionally trained and experienced psychotherapist, her goal is to help people talk their problems out to help them get through life by implementing healthy practices. She stays focused on providing therapy and consultation based on professional guidelines and ethical standards. As part of her commitment to offering quality, informed care, she tries her best to make
                </p>

              </div>
              <div data-aos="fade-right" data-aos-duration="1000">
                <button className='customBtn'>Learn More  <IoIosArrowForward />  </button>
              </div>
            </div>

          </Col>
          <Col xs={12} md={6} lg={5}>
            <div data-aos="fade-left" data-aos-duration="1000">
              <img src={about} className='img-fluid rounded-3' alt="" />
            </div>
          </Col>
        </Row>


      </Container>
      <div className="">

        <Testimonials />
      </div>

      <Footer />

    </>
  )
}

export default About