import React  from "react";
import slider1 from "../../assets/Images/slider1.jpg";
import Carousel from 'react-bootstrap/Carousel';
import "../Slider/SliderStyle.css";
import { IoIosArrowForward } from "react-icons/io"
const Slider = () => {
  
    return(
        <>
  <div className="SliderContainer">

<Carousel className="" fade>
      <Carousel.Item>
        <img
          className="img-fluid"
          src={slider1}
          id="sliderImg"
          alt="Third slide"
        />
       <Carousel.Caption >
                            <div className='caption'>

                                <p id='TopSliderHeading'>LOUISE GLOVER PSYCHOTHERAPY AND COUNSELLING</p>

                                <h3 id="TopSliderdisc">The Expert Support For Your Mental Health</h3 >
                                <h3 id="TopSliderdisc">From The  <span id="TopSliderInner">Best Psychotherapist In London</span> </h3>
                                <p id="TopSlidertext">We offer a range of services to meet the unique needs of our clients. Whether you are seeking individual therapy, couples counselling, or support for your family, we have the expertise to help you reach your goals.</p>
                                <button className='customBtn2'>Learn More <IoIosArrowForward/> </button>
                            </div>
                        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
           className="img-fluid"
           src={slider1}
           id="sliderImg"
           alt="Third slide"
        />

<Carousel.Caption>
                            <div className='caption'>

                                <p id='TopSliderHeading'>LOUISE GLOVER PSYCHOTHERAPY AND COUNSELLING</p>

                                <h3 id="TopSliderdisc">The Expert Support For Your Mental Health</h3 >
                                <h3 id="TopSliderdisc">From The  <span id="TopSliderInner">Best Psychotherapist In London</span> </h3>
                                <p id="TopSlidertext">We offer a range of services to meet the unique needs of our clients. Whether you are seeking individual therapy, couples counselling, or support for your family, we have the expertise to help you reach your goals.</p>
                                <button className='customBtn2'>Learn More  <IoIosArrowForward /></button>
                            </div>
                        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
           className="img-fluid"
           src={slider1}
           id="sliderImg"
           alt="Third slide"
        />

<Carousel.Caption>
                            <div className='caption'>

                                <p id='TopSliderHeading'>LOUISE GLOVER PSYCHOTHERAPY AND COUNSELLING</p>

                                <h3 id="TopSliderdisc">The Expert Support For Your Mental Health</h3 >
                                <h3 id="TopSliderdisc">From The  <span id="TopSliderInner">Best Psychotherapist In London</span> </h3>
                                <p id="TopSlidertext">We offer a range of services to meet the unique needs of our clients. Whether you are seeking individual therapy, couples counselling, or support for your family, we have the expertise to help you reach your goals.</p>
                                <button className='customBtn2'>Learn More <IoIosArrowForward /></button>
                            </div>
                        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
</div>
        </>
    );
};
export default Slider;