import React, { useState } from 'react';
import NavTopBar from "../../Components/Nav/NavTopBar"
import Footer from '../../Components/Footer/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import "../Contact/ContactStyle.css";
import { IoIosArrowForward } from "react-icons/io";
import InputGroup from 'react-bootstrap/InputGroup';
import { FaFacebookF } from 'react-icons/fa';
import Alert from 'react-bootstrap/Alert';
import { AiOutlineTwitter, AiOutlineInstagram, AiFillYoutube } from 'react-icons/ai';
function Contact() {
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [textarea, setTextArea] = useState('');
  const HandleSubmit = (e) => {
    e.preventDefault();
    if (name.length === 0 || email.length === 0 || textarea.length === 0) {
      setError(true);
      return;
    }
    console.log(name, email, textarea);


  }

  return (



    <>
      <NavTopBar />

      <div className="contactContainer"></div>
      <Container style={{ width: "80%" }}>
        <Row>
          <Col lg="6" sm="12" md="12" className='my-auto'>
            <div data-aos="fade-right" data-aos-duration="1000">

              <h4 className='contactTitle' >CONTACT US</h4>
              <h1 className='contactHead'>Get In Touch</h1>

              <p className='contactText'>Connect with us immediately if you are looking for online therapy counselling or a face-to-face session.</p>
            </div>


            <div data-aos="fade-right" data-aos-duration="1000">

              <h4 className='contactTitle'>Information</h4>
              <ul className='p-0'>
                <li className='contactInfo'>Phone: +021 3456 789</li>
                <li className='contactInfo'>Email: info@lgtherapy.co.uk</li>
                <li className='contactInfo'>Redwood Princess Boulevard, Rose Street A60, United States</li>
              </ul>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000">

              <h4 className='contactTitle'>Follow Us</h4>
              <ul className='contacticonContainer'>
                <li>  <FaFacebookF id='sIcon' /></li>
                <li> <AiOutlineTwitter id='sIcon' /></li>
                <li> <AiOutlineInstagram id='sIcon' /></li>
                <li> <AiFillYoutube id='sIcon' /></li>
              </ul>
            </div>
          </Col>

          <Col lg="6" sm="12" md="12" className='py-5'>

            <div className="contactFormContainer" data-aos="fade-left" data-aos-duration="1000">
              <Form>

                <div className="fomrTopHeading">
                  <h2 className='contactHeading'>Send Us Messages</h2>

                </div>
                <Form.Group className="mb-3 mx-5" controlId="formBasicPassword">

                  <Form.Control type="text" value={name} onChange={(e) => { setName(e.target.value) }} id='nameInput' placeholder="Full Name" />
                </Form.Group>
                {error && name.length <= 0 ? (
                  <Alert variant="danger" className='mb-3 mx-5'>
                    Name can't be Empty!
                  </Alert>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3 mx-5" controlId="formBasicPassword">

                  <Form.Control type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} id="emailInput" placeholder="Email Address" />
                </Form.Group>
                {error && email.length <= 0 ? (
                  <Alert variant="danger" className='mb-3 mx-5'>
                    Email can't be Empty!
                  </Alert>
                ) : (
                  ""
                )}
                <InputGroup className="mb-3" id="textAreaField">

                  <Form.Control as="textarea" value={textarea} onChange={(e) => { setTextArea(e.target.value) }} className='p-3' aria-label="With textarea" placeholder="Message" />
                </InputGroup>

                {error && textarea.length <= 0 ? (
                  <Alert variant="danger" className='mb-3 mx-5'>
                    Type Message can't be Empty!
                  </Alert>
                ) : (
                  ""
                )}

                <div className="mx-5">
                  <button className='contactCustombtn' onClick={HandleSubmit} type="submit">
                    Send Message < IoIosArrowForward />
                  </button>

                </div>

              </Form>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.540423482658!2d-0.1221286236106782!3d51.50330061100306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2s!4v1681382345551!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </Container>
      <Footer />
    </>
  )
}

export default Contact