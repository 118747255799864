import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../AboutSec/AboutSecStyle.css";
import about from "../../assets/Images/about.jpg"
import { IoIosArrowForward } from "react-icons/io";
function AboutSec() {
  return (
    <>
    
    <Container className='AboutInnerSec' style={{width:"80%"}}>
<Row>
        <Col xs={12} md={6} lg={7}>
<div className="left">
    <h3 className='about-Heading' data-aos="fade-right"  data-aos-duration="1000">ABOUT US</h3>
    <h1 className='aboutSubHeading' data-aos="fade-up"  data-aos-duration="1000">
    Psychotherapy For Individuals To Help Them Recover And Heal
    </h1>
    <div>
        <p className='aboutText' data-aos="fade-down" data-aos-duration="1000">
        We understand that seeking help for mental health issues can be intimidating, and that is why we strive to create a welcoming and non-judgmental atmosphere where clients feel comfortable and supported.

Whether you are struggling with anxiety, depression, trauma, or any other mental health concern, we are here to provide you with the tools and support you need to move forward and lead a fulfilling, meaningful life. So, if you’re ready to take the first step toward a brighter future, please don’t hesitate to reach out to us. We’re here to help.
        </p>

    </div>
    <div data-aos="fade-right"  data-aos-duration="1000">
        <button className='aboutcustomBtn'>Learn More  <IoIosArrowForward/>  </button>
    </div>
</div>

        </Col>
        <Col  xs={12} md={6} lg={5}>
            <div data-aos="fade-left"  data-aos-duration="1000">
                <img src={about} className='img-fluid rounded-3' alt="" />
            </div>
        </Col>
      </Row>

   
    </Container>

 
    </> )
}



export default AboutSec
