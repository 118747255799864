import React from 'react'
import NavTopBar from '../../Nav/NavTopBar'
import Footer from '../../Footer/Footer'
import { Container, Row, Col } from 'react-bootstrap'
import "../Child/ChildStyle.css";
import  booksec  from "../../../assets/Images/book-now/booksec.jpg"
import help from "../../../assets/Images/help.jpg"
function Child() {
  return (
    <>

      <NavTopBar />

      <div className='childSection'></div>
      <Container style={{ width: "70%" }}>
        <Row>
          <Col lg="6" md="6" sm="12" className='py-5'>
            <div className="">
              <h2 className='childHeading' data-aos="fade-right" data-aos-duration="1000">SERVICES</h2>
              <h1 className='childSubHeading' data-aos="fade-up" data-aos-duration="1000">Family Psychotheraphy</h1>
              <p className='childText' data-aos="fade-up" data-aos-duration="1000"> Providing a safe and supportive atmosphere in which you and your family can candidly discuss your challenges and work towards finding solutions.</p>

              <h3 className='childDesc' data-aos="fade-up" data-aos-duration="1000">What Makes Family Psychotherapy So Effective?</h3>
              <div data-aos="zoom-in" data-aos-duration="1000">
                <img src={booksec} className='img-fluid rounded-3' alt="" />
              </div>
            </div>
          </Col>


          <Col lg="6" md="6" sm="12" className='my-auto'>
<div className="">
  <h1 className='rightText' data-aos="fade-left" data-aos-duration="1000">Families can be very complicated at times. Family psychotherapy helps mend bonds, alter negative behavior, and resolve conflicts. The therapist works closely with the family to analyze the core problem. Using therapy, they can assist families in helping each other,</h1>

  <p className='rightDesc' data-aos="fade-left" data-aos-duration="1000">
  Countless circumstances and events can make a family suffer mental, health, or even financial problems. When things turn overwhelming within the family dynamics, and no way out seems possible, followed by unhealthy patterns. It is best to consider family
  </p>
</div>
          </Col>
        </Row>
      </Container>

      <Container style={{ width: "70%" }}>
        <Row>
          <Col lg="6" md="6" sm="12" className='py-5'>
           <div className="">
            <p className='childText' data-aos="fade-right" data-aos-duration="1000">Family Psychotherapy and Counselling can help with the following:</p>
            <ul data-aos="fade-up" data-aos-duration="1000">
              <span>child and adolescent behavior</span>
              <li>⦁ adult mental health</li>
              <li>⦁ parenting issues</li>
              <li>⦁ divorce and separation</li>
              <li>⦁changes in family life psychotherapy
</li>
            </ul>

            <h2 className='childDesc py-4' data-aos="fade-right" data-aos-duration="1000">Why Louise Glover Psychotherapy and Counselling?</h2>

            <p className='childText' data-aos="fade-right" data-aos-duration="1000">Our therapists are trained to address a wide range of family issues, including communication breakdowns, parenting challenges, and conflicts between siblings or spouses. We understand that every family is unique, and we will work with you to create a customized treatment plan that fits your needs.</p>


            <p className='childText' data-aos="fade-right" data-aos-duration="1000">Our sessions are designed to be a safe and supportive space for you and your family to discuss your challenges and work towards finding solutions. We’ll help you learn new skills and strategies for managing conflicts and improving your relationships with one another.</p>
           </div>
          </Col>


          <Col lg="6" md="6" sm="12" className='my-auto'>
          <div data-aos="fade-left" data-aos-duration="1000">
                <img src={help} className='img-fluid rounded-3' alt="" />
              </div>

              <h4 className='childDesc py-4' data-aos="fade-left" data-aos-duration="1000">Our Therapy Sessions</h4>

              <p className='childText' data-aos="fade-up" data-aos-duration="1000">A family therapy session can last from 45 minutes to 1.5 hours; it can be multiple meetings several within a year. The clients talk about what they are feeling and important family beliefs that help build a specific plan for the therapy sessions. Don’t let tension and strife ruin your family’s happiness. Contact the best <b>psychotherapists in London</b> to learn more about our family psychotherapy services and start building a healthier, more harmonious family dynamic.</p>
          </Col>
        </Row>
      </Container>

      <Footer />












    </>
  )
}

export default Child