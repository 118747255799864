import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import "../Cards/CardsStyle.css";
import {FaPeopleArrows} from "react-icons/fa";
import {FaBrain} from "react-icons/fa";
import {BsFillPersonCheckFill} from "react-icons/bs";
function Cards(props) {
  return (
    <>
    
    <Container className='cardContainer'  style={{ width: '80%', marginBottom: "10px" }}>
                <Row>
                    <Col className='p-0' xs={12} lg={4}>
                        <Card style={{ width: '100%' }} className='card border-0' >
                            <Card.Body className='cardColor' >
                              <div  data-aos="fade-right" data-aos-duration="1000">
                                <div className="icon">
                                <FaPeopleArrows style={{color:'#fff'}}/>
                                </div>

                                <Card.Title className='cardTittle' >Counseling</Card.Title>

                                <Card.Text className='cardPara'>
                                    Providing professional and honest consultation to guide those suffering from mental health issues. If you need any support or suffering from depressing thoughts, talk to a therapist immediately.
                                </Card.Text>
                                </div>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col className='p-0' xs={12} lg={4}>
                        <Card style={{ width: '100%' }} className='card  border-top-0 border-bottom-0'>
                            <Card.Body className='cardColor'>
                            <div  data-aos="fade-up" data-aos-duration="1000">
                                <div className="icon">
                                    <FaBrain/>
                                </div>
                                <Card.Title className='cardTittle'> Psychotheraphy</Card.Title>

                                <Card.Text className='cardPara'>
                                    Providing professional and honest consultation to guide those suffering from mental health issues. If you need any support or suffering from depressing thoughts, talk to a therapist immediately.
                                </Card.Text>
</div>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col className='p-0' xs={12} lg={4}>
                        <Card style={{ width: '100%' }} className='card border-0'>
                            <Card.Body className='cardColor'>
                            <div  data-aos="fade-left" data-aos-duration="1000">
                                <div className="icon">
                                    <BsFillPersonCheckFill/>
                                </div>
                                <Card.Title className='cardTittle'>Self Management</Card.Title>

                                <Card.Text className='cardPara'>
                                    Providing professional and honest consultation to guide those suffering from mental health issues. If you need any support or suffering from depressing thoughts, talk to a therapist immediately.
                                </Card.Text>
</div>
                            </Card.Body>
                        </Card>


                    </Col>
                </Row>
            </Container>
    
    </>
  )
}



export default Cards
