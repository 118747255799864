import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Testimonials/TestimonialStyle.css";
import testimo1 from "../../assets/Images/testimo1.jpg";
import testimo2 from "../../assets/Images/testimo2.jpg";
import { IoIosArrowForward } from "react-icons/io"
function Testimonials() {
    return (
        <>
            <div className="">


                <Container style={{ width: '80%', marginBottom: "10px" }}>
                    <Row justify-content-center>
                        <Col sx={12} md={6} lg={10}>
                            <div className="">
                                <h5 className='t-Heading' data-aos="fade-right" data-aos-duration="1000"> TESTIMONIALS</h5>
                            </div>
                        </Col>

                        <Col sx={12} md={6} lg={2}>

                            <div data-aos="fade-left" data-aos-duration="1000">
                                <button className='testCustomBtn'>More Testimonials  <IoIosArrowForward /></button>
                            </div>
                        </Col>
                    </Row>


                </Container>



                <Container className='' style={{ width: '80%', marginBottom: "50px" }}>
                    <Row justify-content-center>
                        <Col sx={12} md={6} lg={5} className='mt-5'>
                            <div data-aos="fade-up" data-aos-duration="1000" className='mt-5'>
                                <h1 className='leftHeading mt-5'>Real Life Stories</h1>
                                <div className="leftBox">
                                    <h3 className='boxHeading'>Awesome Services! <span className='Comma'>&#x275D;</span>
                                    </h3>
                                    <p className='boxText'>We are proud to share some of our inspiring success stories One of my biggest achievements this year is standing up for myself. It is all because of Psychotherapy that I was able to fight my insecurities. It was tough, but I did it.</p>



                                    <div className="lefBoxCard">
                                        <img src={testimo1} className='leftBoxImage' alt="" />
                                        <h2 className='leftBoxText'>Julian Mason </h2>
                                        
                                        
                                      
                                    </div>

                                </div>


                            </div>



                        </Col>

                        <Col sx={12} md={6} lg={7}>

                            <div mt-sm-5 data-aos="fade-down" data-aos-duration="1000">
                                {/* <h1>Awesome Services!</h1> */}
                                <div className="rightBox">
                                    <h3 className='leftHeading'>Awesome Services! <span className='CommaRight'>&#x275D;</span></h3>
                                    <div className="innerBox">


                                        <p className='innerText'>

                                            I am a completely different person today. I have had a lot of family issues over the past few years. And I couldn’t find any way out of it. With Psychotherapy, I was able to mend the broken connections with my loved ones.
                                        </p>
                                    </div>
                                    <div className="lefBoxCard">
                                        <img src={testimo2} className='leftBoxImage' alt="" />
                                        <h2 className='leftBoxText'>Emilia Wyatt</h2>

                                    </div>


                                </div>


                            </div>
                        </Col>
                    </Row>


                </Container>


            </div>
        </>
    )
}

export default Testimonials