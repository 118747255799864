import React from 'react'
import Child from '../Child/Child'
function Family(props) {
  return (




    <>
   



<Child/>






    </>
  )
}



export default Family
